<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
        <h4 class="text-center">
            <span class="text-muted">مرحباً</span>
            {{ teacher.name }}.
        </h4>
    </div>
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_xteacher/attend')">
                <h3>
                    <img :src="require('@/assets/images/lessons.png')" alt="">
                    تحضير الحصص
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_xteacher/report')">
                <h3>
                    <img :src="require('@/assets/images/report.png')" alt="">
                    تقرير الغياب
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_xteacher/weeklyplan')">
                <h3>
                    <img :src="require('@/assets/images/calendar.png')" alt="">
                    خطة التعلم الاسبوعي
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_xteacher/rate')">
                <h3>
                    <img :src="require('@/assets/images/rate.png')" alt="">
                    تقييم الطلاب
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_xteacher/forward')">
                <h3>
                    <img :src="require('@/assets/images/forward.png')" alt="">
                    تحويل طالب للوكيل
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_xteacher/health')">
                <h3>
                    <img :src="require('@/assets/images/health.png')" alt="">
                    الحالات الصحية للطلاب
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_xteacher/mails')">
                <h3>
                    <img :src="require('@/assets/images/mails.png')" alt="">
                    التعاميم
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_xteacher/asks')">
                <h3>
                    <img :src="require('@/assets/images/asks.png')" alt="">
                    المسائلات
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_xteacher/myreport')">
                <h3>
                    <img :src="require('@/assets/images/myreport.png')" alt="">
                    تقرير الحضور الخاص بي
                </h3>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="card card-body item text-center" @click="$router.push('/_xteacher/tasks')">
                <h3>
                    <img :src="require('@/assets/images/tasks.png')" alt="">
                    المهام
                </h3>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            teacher: JSON.parse(localStorage.getItem('teacher')),
        }
    }
}
</script>

<style>
.item{
    border: solid 2px #fff;
}
.item:hover{
    border: solid 2px #ddd;
    cursor: pointer;
    background: #fafafa;
}
.item img{
    width: 64px;
}
</style>